import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from '../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import ArticleLink from '../../components/articles/ArticleLink'
import NewsArticleLink from '../../components/articles/Listings/NewsArticleLink'
import Newsletter from '../../components/Newsletter'

import ArrowRightSVG from '../../assets/svg/arrow-right.svg'

export default function DatapoolTemplate({ data }) {
  const {
    datapool,
    site,
    productImage,
    manufacturerImage,
    wholesalerImage,
    usersImage,
    relatedArticles,
  } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${datapool.categoryLink}/${datapool.slug}`
  )
  const NONE = `<ul><li><i>None this month</i></li></ul>`

  const breadcrumbs = [
    {
      name: 'News',
      uri: '/news',
    },
    {
      name: datapool.category,
      uri: datapool.categoryLink,
    },
    {
      name: datapool.title,
      uri: `${datapool.categoryLink}/${datapool.slug}`,
    },
  ]

  const {
    newManufacturersLeftNode,
    newManufacturersRightNode,
    newWholesalersLeftNode,
    newWholesalersRightNode,
    manufacturerCancellationsNode,
    manufacturersWithdrawnNode,
  } = datapool

  return (
    <Page
      className="p-single-datapool p-newsArticle has-fingerPrints"
      datoIndex
    >
      <HelmetDatoCms seo={datapool.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} />
      {datapool.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: datapool.canonicalUrl,
              href: datapool.canonicalUrl,
            },
          ]}
        />
      )}

      <article
        id={datapool.title}
        className="c-article c-articleDatapool c-articleFull"
      >
        <div className="container py-5">
          <header>
            <HeaderInfo
              date={datapool.pubDateTime}
              category={datapool.category}
              categoryLink={datapool.categoryLink}
            />

            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <h1 className="c-articleTitle">{datapool.title}</h1>
              </div>
            </div>
          </header>

          <section>
            <div className="row">
              <div className="col-12 col-lg-3 mb-2">
                <ShareButtons shareUrl={shareUrl} />
              </div>
              <div className="col-12 col-lg-9">
                <div className="c-articleDatapool__header">
                  <div className="row">
                    <div className="c-articleDatapoolStat col-sm-4">
                      <Img
                        fluid={productImage.childImageSharp.fluid}
                        alt="Product specifications"
                        className="c-articleDatapoolStat__image img-fluid"
                      />
                      <p className="c-articleDatapoolStat__text">
                        <strong>
                          {datapool.productCount.toLocaleString()}
                        </strong>
                        Product specifications
                      </p>
                    </div>
                    <div className="c-articleDatapoolStat col-sm-4">
                      <Img
                        fluid={manufacturerImage.childImageSharp.fluid}
                        alt="Manufacturers"
                        className="c-articleDatapoolStat__image img-fluid"
                      />
                      <p className="c-articleDatapoolStat__text">
                        <strong>
                          {datapool.manufacturerCount.toLocaleString()}
                        </strong>
                        Food manufacturers
                      </p>
                    </div>
                    <div className="c-articleDatapoolStat col-sm-4">
                      <Img
                        fluid={wholesalerImage.childImageSharp.fluid}
                        alt="Wholesalers"
                        className="c-articleDatapoolStat__image img-fluid"
                      />
                      <p className="c-articleDatapoolStat__text">
                        <strong>
                          {datapool.wholesalerCount.toLocaleString()}
                        </strong>{' '}
                        Wholesalers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <div className="c-articleFull__section">
                  <h2 className="c-articleDatapool__sectionHeader">
                    New Manufacturers
                  </h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="c-articleDatapool__sectionBody"
                        dangerouslySetInnerHTML={{
                          __html:
                            newManufacturersLeftNode.childMarkdownRemark.html ||
                            NONE,
                        }}
                      />
                    </div>
                    {newManufacturersRightNode.childMarkdownRemark.html && (
                      <div className="col-md-6">
                        <div
                          className="c-articleDatapool__sectionBody"
                          dangerouslySetInnerHTML={{
                            __html:
                              newManufacturersRightNode.childMarkdownRemark
                                .html,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="c-articleFull__section">
                  <h2 className="c-articleDatapool__sectionHeader">
                    New Wholesalers
                  </h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="c-articleDatapool__sectionBody"
                        dangerouslySetInnerHTML={{
                          __html:
                            newWholesalersLeftNode.childMarkdownRemark.html ||
                            NONE,
                        }}
                      />
                    </div>
                    {newWholesalersRightNode.childMarkdownRemark.html && (
                      <div className="col-md-6">
                        <div
                          className="c-articleDatapool__sectionBody"
                          dangerouslySetInnerHTML={{
                            __html:
                              newWholesalersRightNode.childMarkdownRemark.html,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="c-articleFull__section">
                  <h2 className="c-articleDatapool__sectionHeader">
                    Manufacturer cancellations
                  </h2>
                  <div
                    className="c-articleDatapool__sectionBody"
                    dangerouslySetInnerHTML={{
                      __html:
                        manufacturerCancellationsNode.childMarkdownRemark
                          .html || NONE,
                    }}
                  />
                </div>
                <div className="c-articleFull__section">
                  <h2 className="c-articleDatapool__sectionHeader">
                    Manufacturers withdrawn by Erudus
                  </h2>
                  <div
                    className="c-articleDatapool__sectionBody"
                    dangerouslySetInnerHTML={{
                      __html:
                        manufacturersWithdrawnNode.childMarkdownRemark.html ||
                        NONE,
                    }}
                  />
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="c-datapoolUsers">
                      <div className="row">
                        <div className="col-12 col-xl-6 py-4 text-center">
                          <ArrowRightSVG className="mr-3" />
                          <Link
                            to="/whos-using-erudus"
                            className="c-datapoolUsers__link"
                          >
                            View entire list of all Erudus users
                          </Link>
                        </div>
                        <div className="col-12 col-xl-6">
                          <Img
                            fluid={usersImage.childImageSharp.fluid}
                            alt="Erudus users"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {datapool.articleLink.length > 0 && (
                  <div className="row mt-5">
                    <div className="c-articleContentCol col-12 col-lg-12">
                      {datapool.articleLink.map((article) => (
                        <ArticleLink key={article.id} article={article} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </article>

      {/* <!-- Related --> */}
      <section
        id="latest-editorial"
        className="c-latestEditorial"
        data-datocms-noindex
      >
        <div className="container">
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h3 className="c-latestEditorial__header__title">
                  Related News
                </h3>
                <Link to="/news" className="c-postReturnLink">
                  <img src="/icons/arrow-right.svg" className="c-icon mr-2" />
                  <span className="c-postReturnLink__text">All News</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {relatedArticles.edges.map(({ node }) => (
                <div key={node.slug} className="col-6 col-lg-4">
                  <NewsArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query DatapoolQuery($slug: String!) {
    datapool: datoCmsDatapool(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      canonicalUrl
      displayDate
      pubDateTime
      category
      categoryLink
      productCount
      manufacturerCount
      wholesalerCount
      wholesalerCount
      newManufacturersLeftNode {
        childMarkdownRemark {
          html
        }
      }
      newManufacturersRightNode {
        childMarkdownRemark {
          html
        }
      }
      newWholesalersLeftNode {
        childMarkdownRemark {
          html
        }
      }
      newWholesalersRightNode {
        childMarkdownRemark {
          html
        }
      }
      manufacturerCancellationsNode {
        childMarkdownRemark {
          html
        }
      }
      manufacturersWithdrawnNode {
        childMarkdownRemark {
          html
        }
      }
      articleLink {
        ...NewsArticleLinkFragment
        ...EditorialArticleLinkFragment
        ...DatapolArticleLinkFragment
        ...RecipeArticleLinkFragment
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    productImage: file(
      relativePath: { eq: "articles/data-pool/products.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    manufacturerImage: file(
      relativePath: { eq: "articles/data-pool/manufacturers.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wholesalerImage: file(
      relativePath: { eq: "articles/data-pool/wholesalers.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    usersImage: file(
      relativePath: { eq: "articles/data-pool/icon-order.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    relatedArticles: allDatoCmsDatapool(
      filter: { slug: { ne: $slug } }
      sort: { fields: displayDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          relPath
          category
          categoryLink
          pubDateTime
          thumbnailImage {
            url
            alt
            title
            customData
          }
        }
      }
    }
  }
`
